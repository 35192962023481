import './App.css';
import React from "react";
import './styles.scss';
import Header from "./Header";
import Error from "./Error";
import Login from "./pages/Login";
import Home from "./pages/Homepage";
import Forgot from "./pages/Forgot";
import Logout from "./pages/Logout";
import Signup from "./pages/Signup";
import Verify from "./pages/Verify";
import Fridge from "./pages/fridge/Fridge";
import Fridges from "./pages/fridge/Fridges";
import FridgeCreate from "./pages/fridge/FridgeCreate";
import FridgeItemAdd from "./pages/fridge/FridgeItemAdd";
import FridgeItemEdit from "./pages/fridge/FridgeItemEdit";

import Recipe from "./pages/recipe/Recipe";
import RecipeItem from "./pages/recipe/RecipeItem";
import Favorites from "./pages/recipe/Favorites";

import Household from "./pages/household/Household";
import Invitations from "./pages/household/Invitations";
import List from "./pages/list/List";

import PremiumSignup from "./pages/premium/PremiumSignup";

import {Link} from "react-router-dom";
import {ClearAlert, GetAlert, HasAlert} from "./utils/AlertUtils";

import {IsLoggedIn} from "./utils/TokenStorage";
import Budget from "./pages/fridge/Budget";
import Income from "./pages/fridge/Income";
import Contact from "./pages/fridge/Contact";
import Blog from "./pages/Blog";

const components = {
    fridge: Fridge,
    fridges: Fridges,
    fridgeCreate: FridgeCreate,
    fridgeItemAdd: FridgeItemAdd,
    fridgeItemEdit: FridgeItemEdit,
    recipes: Recipe,
    recipeItem: RecipeItem,
    household: Household,
    invitations: Invitations,
    premiumSignup: PremiumSignup,
    login: Login,
    logout: Logout,
    signUp: Signup,
    list: List,
    favorites: Favorites,
    forgot: Forgot,
    home: Home,
    verify: Verify,
    budget: Budget,
    income: Income,
    contact: Contact,
    blog: Blog
}

function App(props) {
  const TargetComponent = components[props.page];

  return (
    <div className="App">
      <div className={IsLoggedIn() ? "main-body container" : "main-body"}>
          {IsLoggedIn() &&
              <div className="d-flex">
                  <div className="col-sm-0 col-md-2 mt-3">
                      <h6 className="header-text-name larger-header-show">MoneyManTracker</h6>
                      <Header/>
                  </div>
                  <div className="col-sm-12 col-md-10 mt-2">
                      <h5 className="header-text-name smaller-header-show">MoneyManTracker</h5>
                      <TargetComponent/>
                  </div>
              </div>
          }

          {!IsLoggedIn() &&
              <>
                  <TargetComponent/>
              </>
          }
      </div>
    </div>
        );
        }

        export default App;
