import React, {useState, useEffect} from "react";
import {useParams} from "react-router";
import {PerformRequest} from "../../utils/JsonFetcher";
import HouseholdMember from "./HouseholdMember";
import {Link, redirect} from "react-router-dom";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";

function Household() {
    let { id } = useParams();

    const [household, setHousehold] = useState("");
    const [invitations, setInvitations] = useState([]);
    const [loginError, setLoginError] = useState(false);
    const [limits, setLimits] = useState("");

    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            let result = await PerformRequest('households/' + id);
            let result2 = await PerformRequest('invitations');
            let result3 = await PerformRequest('users/limits');

            if(result3) {
                setLimits(result3);

                if(result3.currentUsers + 1 > result3.userLimit) {
                    setLoginError(true);
                }
            }

            if(result) {
                setHousehold(result);
                setInvitations(result2);
                setLoading(false);
            }
        }

        fetchData()
            .catch(console.error);
    }, []);

    const handleSubmit = async() => {
        let result = await PerformRequest('invitation/' + household.id, "PUT", {email: email});

        if(result) {
            window.location.reload();
        }
    };

    if(loading) {
        return <div>
            <ClipLoader
                className="ml-5"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>;
    }

    var mappedInvitations = "";

    if(invitations && invitations.length > 0) {
        mappedInvitations = invitations.map(({id, email}) => {
            return <tr>
                <td>{email}</td>
            </tr>
        })
    }


    return (
        <div key={household.id}>
            <h3>Users</h3>

            { mappedInvitations &&
                <div className="card card-section shadow p-3 mb-3 rounded">
                    <div className="card-header card-header-tile mb-4">Invitations</div>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <td>Email</td>
                        </tr>
                        </thead>
                        <tbody>
                            {mappedInvitations}
                        </tbody>
                    </table>
                </div>
            }

            { !loginError &&
                <div className="card card-section shadow p-3 mb-3 rounded">
                    <div className="card-header card-header-tile mb-4">Invite User</div>
                    <div className="card-body">
                        <div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Email:</span>
                                <input
                                    id="email"
                                    className="form-control"
                                    type="text"
                                    value={email}
                                    placeholder="myuser@example.com"
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>

                            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            }

            {loginError &&
                <div className="card card-section shadow p-3 mb-3 rounded">
                    <div className="card-header card-header-tile mb-4">Invite User</div>
                    <div className="card-body">
                        <h5 className="text-danger"><i className="bi bi-exclamation-triangle-fill"></i> Please <Link
                            to="/premium/signup">upgrade</Link> to add more Users!</h5>
                    </div>
                </div>
            }

            <ul className="list-group my-2">
                <HouseholdMember items={household.users}/>
                </ul>
            </div>
            );
            }

            export default Household;