import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";

import Markdown from 'react-markdown'

function Blog() {
    const file_name = 'budgeting.md';
    const [post, setPost] = useState('');

    useEffect(() => {
        import(`./blog/${file_name}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setPost(res));
            })
            .catch(err => console.log(err));
    });

    return (
        <div className="mt-5 container">
            <div className="card card-section shadow p-3 mb-3 rounded">
                <div className="card-header card-header-tile mb-4">Blog</div>

                <div className="card-body">
                    <Markdown>{post}</Markdown>

                    <div>
                        <Link to="/premium/signup">
                            <button className="btn btn-primary">
                                Sign up now
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

                );
                }

                export default Blog;