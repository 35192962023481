import React, {useState} from "react";
import CreatableSelect from 'react-select/creatable';
import {PerformRequest} from "../../utils/JsonFetcher";
import ClipLoader from "react-spinners/ClipLoader";

function CategoryHelper({id, defaultSelectedValue, extraOptions, replacementOptions, otherHandler}) {
    const [loading, setLoading] = useState(false);
    const [saveComplete, setSaveComplete] = useState(false);

    var types = [
        {value: "Alcohol", label: "Alcohol"},
        {value: "Baby", label: "Baby"},
        {value: "Car Maintenance", label: "Car Maintenance"},
        {value: "Car Loan", label: "Car Loan"},
        {value: "Cash", label: "Cash"},
        {value: "Credit Card", label: "Credit Card"},
        {value: "Daycare", label: "Daycare"},
        {value: "Dental", label: "Dental"},
        {value: "Dining Out", label: "Dining Out"},
        {value: "Electric Bill", label: "Electric Bill"},
        {value: "Electronics", label: "Electronics"},
        {value: "Food", label: "Food"},
        {value: "Gaming", label: "Gaming"},
        {value: "Groceries", label: "Groceries"},
        {value: "Hospital", label: "Hospital"},
        {value: "Income", label: "Income"},
        {value: "Loan", label: "Loan"},
        {value: "Medical", label: "Medical"},
        {value: "Membership", label: "Membership"},
        {value: "Misc", label: "Misc"},
        {value: "Mortgage", label: "Mortgage"},
        {value: "Payment", label: "Payment"},
        {value: "Paypal", label: "Paypal"},
        {value: "Public Transportation", label: "Public Transportation"},
        {value: "Salary", label: "Salary"},
        {value: "Shopping", label: "Shopping"},
        {value: "Student Loan", label: "Studen Loan"},
        {value: "Ticket", label: "Ticket"},
        {value: "Utility", label: "Utility"},
        {value: "Vacation", label: "Vacation"},
        {value: "Water Bill", label: "Water Bill"},
        {value: "Venmo", label: "Venmo"},
        {value: "Zelle", label: "Zelle"}
    ];

    if(replacementOptions && replacementOptions.length > 0){
        types = replacementOptions;
    }

    if(extraOptions && extraOptions.length > 0){
        extraOptions.map((item) => {
            item.value = item.label;

            if(!types.find(type => type.value === item.value)) {
                types.push(item)
            }
        })

        types.sort((a, b) => a.label.localeCompare(b.label));
    }

    var selectedType = types.find(o => o.value === defaultSelectedValue);
    if(defaultSelectedValue && !selectedType) {
        var newSelected = {value: defaultSelectedValue, label: defaultSelectedValue};
        types.push(newSelected);
        var selectedType = types.find(o => o.value === defaultSelectedValue);
    }

    const SaveCategoryHandler = async (e) => {
      if(e && e.value) {
          if (otherHandler) {
              otherHandler(e.value);
          } else {
              setLoading(true);
              let result = await PerformRequest('fridge/item/' + id + "/category", 'PUT', {name: e.value});
              setLoading(false);
              setSaveComplete(true);
          }
      }
    };

    return (
        <>
            <div className="d-flex justify-content-between">
                <div className="col-11">
                    <CreatableSelect
                        className="basic-single form-control"
                        classNamePrefix="select"
                        defaultValue={selectedType}
                        isClearable
                        isSearchable
                        name="color"
                        options={types}
                        onChange={SaveCategoryHandler}
                    />
                </div>

                {loading &&
                    <div className="col-1">
                        <ClipLoader
                            className="ml-5"
                            loading={loading}
                            size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                }

                {saveComplete &&
                    <div className="col-1 ml-5 top-thumb-spacing">
                        <i class="bi bi-hand-thumbs-up-fill"></i>
                    </div>
                }
            </div>
        </>
    );
}

export default CategoryHelper;