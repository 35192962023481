import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import CurrencyFormat from 'react-currency-format';
import CategoryHelper from "./CategoryHelper";
import {PerformRequest} from "../../utils/JsonFetcher";
import ClipLoader from "react-spinners/ClipLoader";
import Input from "./Input";
import Modal from "../../components/Modal";
import Rename from "./RenameForm";
import RenameForm from "./RenameForm";

function FridgeItem({selectedItems, items, CheckMethod, uncategorizedOnly, categoryOptions}) {
    const [selected, setSelected] = useState([]);
    const [notes, setNotes] = useState([]);

    if(uncategorizedOnly) {
        items = items.filter(item => item.categoryId == null);
    }

    const handleChange = (e, index) => {
        const values = [...notes];
        values[index] = e.target.value;
        setNotes(values);
    }

    const saveNote = async (id, index) => {
        const values = [...notes];
        const chosenNote = values[index];
        let result = await PerformRequest('fridge/item/' + id + '/note', 'PUT', {note: chosenNote});
    };

    const truncate = (str, n) => {
        return (str.length > n) ? str.slice(0, n-1) + '...' : str;
    };

    var mapped = items.map(({ id, name, date, categoryId, categoryName, note, total }, index) =>
        <tr>
            <td>
                <Modal title="Create and apply Category Rule"
                       iconInsteadOfButton="true"
                       iconClass="bi bi-file-ruled-fill"
                       buttonText="Create and apply Category Rule"
                       body={<RenameForm defaultTerm={name} categoryOptions={categoryOptions} />}
                       SaveHandler={""}
                />
            </td>
            <td>{truncate(name, 50)}</td>
            <td>{date}</td>
            <td>
                <CategoryHelper id={id} defaultSelectedValue={categoryName} />
            </td>
            <td>
                <input type="text" defaultValue={note} onChange={(e) => handleChange(e, index)} />
            </td>
            <td>
                <i className="bi bi-floppy-fill" onClick={(e) => saveNote(id, index)}></i>
            </td>
            <td className={total > 0 ? "text-success" : "text-danger"}>{<CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={total} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</td>
        </tr>
    );

    return (
        <table className="table table-responsive">
            <thead className="thead-dark">
            <tr>
                <th></th>
                <th scope="col">Name</th>
                <th scope="col">Date</th>
                <th scope="col">Category</th>
                <th scope="col">Note</th>
                <th></th>
                <th scope="col">Total</th>
            </tr>
            </thead>
            <tbody>
                {mapped}
            </tbody>
        </table>
);
}

export default FridgeItem;