import React, { useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import MainPage from "./fridge/images/main_page.png";
import BudgetPage from "./fridge/images/budget.png";
import ItemsPage from "./fridge/images/items.png";
import PremiumCards from "./premium/PremiumCards";

function HomePage() {
    const navigate = useNavigate();

    return (
        <div>
            <div className="landing landing-full">
                <div className="navbar bg-dark homepage-z-front">
                    <div className="container homepage-container">
                        <div>
                          <h4 className="pt-2">MoneyMan Tracker</h4>
                        </div>
                        <div>
                            <Link to="/login">
                                Login
                            </Link>

                            <Link to="/premium/signup">
                                Register
                            </Link>

                            <Link to="/blog">
                                Blog
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row landing-content homepage-container star-container">
                        <section>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </section>

                        <div className="col-sm-12 col-md-4 mb-3 homepage-z-front">
                            <h4 className="mb-3">Take control of your finances</h4>
                            <h5 className="mb-3">Understand your net worth and quickly see where your accounts are at a
                                glance.</h5>
                            <h5 className="mb-3">Upload your data privately and securely.</h5>

                            <Link to="/premium/signup">
                                <button
                                    className="btn btn-lg btn-outline-primary text-white border-white button-white-hover">
                                    Start Now
                                </button>
                            </Link>
                        </div>

                        <div className="landing-img col-md-8 col-sm-12 homepage-z-front">
                            <Link to="/premium/signup">
                                <img className="img-fluid" src={MainPage}/>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

            <div className="homepage-z-front-most is-relative bg-normal">
                <div className="text-center mt-5 pt-1">
                    <h2>Create a budget and track monthly spending.</h2>
                </div>

                <div className="text-center">
                    <img className="img-fluid" src={BudgetPage}/>
                </div>

                <hr/>

                <div className="text-center mt-5">
                    <h2>See your account spending at a glance.</h2>
                </div>

                <div className="text-center mb-5">
                    <img className="img-fluid" src={ItemsPage}/>
                </div>

                <hr />

                <div className="text-center mt-5 pb-5">
                    <h4>Get Started Now!</h4>
                    <PremiumCards />
                </div>
            </div>

            <footer className="py-3 my-4 border-top">
                <div className="container d-flex">
                    <div className="row">
                        <div>
                            <span className="">© 2024 MoneyManTracker</span>
                        </div>

                        <div>
                            <a target="_blank"
                               href="https://drive.google.com/file/d/1JXhxb5x-0pm1NITu1UWBU0kbwr_VKD1w/view">
                                Privacy Policy</a>
                        </div>

                        <div>
                            <a target="_blank"
                               href="https://drive.google.com/file/d/19mAiYFhRXW2cw-yfoiRbL-QSe4_lxt4J/view">
                                Terms and Conditions</a>
                        </div>
                    </div>

                </div>
            </footer>


        </div>
    );
}

export default HomePage;