import React, {useEffect, useState} from "react";
import {GetUser, IsLoggedIn} from "../../utils/TokenStorage";
import {PerformRequest} from "../../utils/JsonFetcher";
import {Link} from "react-router-dom";
import LineChart from "../fridge/LineChart";
import CurrencyFormat from "react-currency-format";

function PremiumCards() {

    return (
<div className="container signup-container mt-5">
    <div className="row row-cols-sm-1 row-cols-md-3 g-4">
        <div className="col-sm-12">
            <div className="card shadow rounded">
                <div className="d-flex justify-content-between p-4 blue-color-font premium-header">
                    <div className="row">
                        <h3>
                            Free
                        </h3>
                        <h2 className="price-text">
                            $0/mo
                        </h2>
                    </div>
                    <i className="bi bi-graph-up-arrow premium-icon"></i>
                </div>
                <div className="p-4 body-premium">
                    <ul className="list-unstyled">
                        <li className="border-bottom py-2">
                            <i className="bi bi-check2"></i> CSV Import & Export
                        </li>
                        <li className="border-bottom py-2">
                            <i className="bi bi-check2"></i> Share Data Securely without Sharing Bank Access
                        </li>
                        <li className="py-2 border-bottom">
                            <i className="bi bi-check2"></i> E-Mail Support
                        </li>
                        <li className="py-2 border-bottom">
                            <i className="bi bi-check2"></i> 1 User / 10 Accounts
                        </li>
                        <li className="py-2 border-bottom faded-premium-text">
                            <i className="bi bi-check2 faded-premium-text"></i> Ad Free
                        </li>
                    </ul>
                    <Link to="/register?type=free" className="btn-no-border">
                        <button className="btn btn-outline-primary d-block w-100" type="button">
                            Start free trial
                        </button>
                    </Link>
                </div>
            </div>
        </div>

        <div className="col col-sm-12">
            <div className="card shadow rounded">
                <div className="d-flex justify-content-between p-4 blue-color-font premium-header">
                    <div className="row">
                        <h3>
                            Pro
                        </h3>
                        <h2 className="price-text">
                            $4.99/mo
                        </h2>
                    </div>
                    <i className="bi bi-wallet2 premium-icon"></i>
                </div>
                <div className="p-4 body-premium">
                    <ul className="list-unstyled">
                        <li className="border-bottom py-2">
                            <i className="bi bi-check2"></i> CSV Import & Export
                        </li>
                        <li className="border-bottom py-2">
                            <i className="bi bi-check2"></i> Share Data Securely without Sharing Bank Access
                        </li>
                        <li className="py-2 border-bottom">
                            <i className="bi bi-check2"></i> Enhanced Support
                        </li>
                        <li className="py-2 border-bottom">
                            <i className="bi bi-check2"></i> 2 Users / 30 Accounts
                        </li>
                        <li className="py-2 border-bottom">
                            <i className="bi bi-check2"></i> Ad-Free
                        </li>
                    </ul>
                    <button className="text-white btn btn-primary d-block w-100" type="button">
                        <Link to="/register?type=pro">
                        Purchase Now
                        </Link>
                    </button>
                </div>
            </div>
        </div>


        <div className="col">
            <div className="card shadow rounded">
                <div className="d-flex justify-content-between p-4 blue-color-font premium-header">
                    <div className="row">
                        <h3>
                            Enterprise
                        </h3>
                        <h2 className="price-text">
                            $49.99/mo
                        </h2>
                    </div>
                    <i className="bi bi-building premium-icon"></i>
                </div>
                <div className="p-4 body-premium">
                    <ul className="list-unstyled">
                        <li className="border-bottom py-2">
                            <i className="bi bi-check2"></i> CSV Import & Export
                        </li>
                        <li className="border-bottom py-2">
                            <i className="bi bi-check2"></i> Share Data Securely without Sharing Bank Access
                        </li>
                        <li className="py-2 border-bottom">
                            <i className="bi bi-check2"></i> Dedicated Support
                        </li>
                        <li className="py-2 border-bottom">
                            <i className="bi bi-check2"></i> 25 Users / Unlimited Accounts
                        </li>
                        <li className="py-2 border-bottom">
                            <i className="bi bi-check2"></i> Ad Free
                        </li>
                    </ul>
                    <Link to="/register?type=enterprise" className="btn-no-border">
                        <button className="btn btn-outline-primary d-block w-100" type="button">
                            Purchase Now
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</div>
    )
}

export default PremiumCards;