import React, {useState, useEffect, useRef} from "react";
import BudgetCreate from "./BudgetCreate";

function Contact() {
    return (
        <div>
            <div className="row row-cols-sm-12 row-cols-md-12 g-4">
                <div className="col col-sm-12">
                    <div className="card card-tile card-section shadow p-3 mb-3 rounded">
                        <div className="card-header card-header-tile">Contact</div>
                        <div className="card-body">
                            <form
                                action="https://formspree.io/f/xjkvqwwz"
                                method="POST"
                                target="_blank"
                            >
                                <div className="input-group mb-3">
                                    <span className="input-group-text">Email:</span>
                                    <input className="form-control" type="email" name="email"/>
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text">Message:</span>
                                    <textarea className="form-control" name="message"></textarea>
                                </div>
                                    <button className="btn btn-primary" type="submit">Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Contact;