import React, { useState } from 'react';
import {PerformRequest, GET, POST, PUT} from '../utils/JsonFetcher';
import {SetToken, SetUser} from '../utils/TokenStorage';
import {Link, useNavigate, useSearchParams} from "react-router-dom";

function Signup() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Error Registering!");

    const [searchParams, setSearchParams] = useSearchParams();
    var registrationType = searchParams.get("type");

    const handleSubmit = async (event) => {
        event.preventDefault();

        const body = {
            username: username,
            password: password,
            email: email,
            acceptedTerms: acceptedTerms,
            registrationType: registrationType ? registrationType : "free"
        };

        let result = await PerformRequest('users/create', PUT, body, true);

        if(result && !result["errorMessage"]) {
            setLoginError(false);
            navigate('/login?user_success=true')
        } else {
            setErrorMessage(result["errorMessage"]);
            setLoginError(true);
        }
    };

    const PasswordSetRender =
        <div className="form-group">
            <input
                id="password-field"
                className="form-control"
                required=""
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
            />
            <span toggle="#password-field" className="field-icon toggle-password"><i className="bi bi-eye-fill"
                                                                                     onClick={() => {
                                                                                         setShowPassword(true)
                                                                                     }}></i></span>
        </div>;

    const PasswordViewRender =
        <div className="form-group">
            <input
                id="password-field"
                className="form-control"
                required=""
                type="text"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
            />
            <span toggle="#password-field" className="field-icon toggle-password"><i className="bi bi-eye-slash-fill"
                                                                                     onClick={() => {
                                                                                         setShowPassword(false)
                                                                                     }}></i></span>
        </div>

    const InputToRender = showPassword ? PasswordViewRender : PasswordSetRender;

    return (
        <div className="landing landing-full">
            <div className="navbar bg-dark">
                <div className="container homepage-container">
                    <div>
                        <h4 className="pt-2"><Link to="/">MoneyMan Tracker</Link></h4>
                    </div>
                    <div>
                        <Link to="/login">
                            Login
                        </Link>

                        <Link to="/premium/signup">
                            Register
                        </Link>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center login">
                    <div className="col-md-7 col-lg-5">
                        <div className="wrap">
                            <div className="img register"></div>
                            <div className="login-wrap p-4 p-md-5">
                                <div className="d-flex">
                                    <div className="w-100">
                                        <h3 className="mb-4">Register</h3>
                                        {loginError &&
                                            <h5 className="text-danger"><i class="bi bi-exclamation-triangle-fill"></i> {errorMessage} </h5>
                                        }
                                    </div>
                                </div>
                                <form action="#" className="signin-form" onSubmit={handleSubmit}>
                                    <div className="form-group mt-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            required=""
                                            value={email}
                                            placeholder="Email"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            required=""
                                            value={username}
                                            placeholder="Username"
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </div>
                                    {InputToRender}
                                    <div className="form-group form-check mt-3">
                                        <input
                                            id="exampleCheck1"
                                            type="checkbox"
                                            className=""
                                            required=""
                                            value={acceptedTerms}
                                            onChange={(e) => setAcceptedTerms(e.target.value)}
                                        />

                                        <label className="form-check-label text-secondary" htmlFor="exampleCheck1">&nbsp; I accept the <a target="_blank" href="https://drive.google.com/file/d/1JXhxb5x-0pm1NITu1UWBU0kbwr_VKD1w/view">usage and privacy terms.</a></label>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit"
                                                className="form-control btn btn-primary rounded submit px-3">Register
                                        </button>
                                    </div>
                                    <div className="form-group d-md-flex justify-content-between">
                                        <div>
                                            <Link to="/login">Log In</Link>
                                        </div>

                                        <div>
                                            <Link to="/forgot">Forgot Password</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
        ;
}

export default Signup;