import React, {useState, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {PerformRequest, GET, POST, PUT} from '../../utils/JsonFetcher';
import CurrencyFormat from 'react-currency-format';
import Chart from "./Chart";
import {SetAlert} from "../../utils/AlertUtils";
import Select from "react-select";
import LineChart from "./LineChart";
import ClipLoader from "react-spinners/ClipLoader";
import BudgetCreate from "./BudgetCreate";
import Input from "./Input";
import CategoryHelper from "./CategoryHelper";

function Budget() {
    function isEmpty(obj) {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }

        return true;
    }

    const [budget, setBudget] = useState("");
    const [budgetItems, setBudgetItems] = useState([]);
    const [incomeTotal, setIncomeTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState("");

    const [formValues, setFormValues] = useState([]);
    const [toggle, setToggle] = useState(false);

    const inputRef = useRef();
    const selectRef = useRef();
    const select2Ref = useRef();

    const handleChange = (e, index) => {
        const values = [...formValues];
        values[index].value = e.target.value;
        setFormValues(values);
    };

    const handleSubmit = async () => {
      const values = [...formValues];

      var payload = [];
      values.map((value, index) => {
          var payloadObject = {};

          value.map((item, index) => {
              payloadObject[item.type] = item.value;
          })

          payload.push(payloadObject);
      })

      if(budgetItems.length > 0) {
          budgetItems.map((item, index) => {
              var payloadObject = {};
              payloadObject["name"] = item.name;
              payloadObject["amount"] = item.total;
              payloadObject["category"] = item.categoryName;
              payload.push(payloadObject);
          })
      }

       let result = await PerformRequest('budget/' + budget.id + '/items', PUT, {items: payload});

       if(result) {
            window.location.reload();
        }
    };

    const handleAddField = (e) => {
        e.preventDefault();
        const values = [...formValues];
        values.push([{
            label: "Name",
            type: "name",
            value: inputRef.current.value,
        },
        {
            label: "Amount",
            type: "amount",
            value: selectRef.current.value,
        },
        {
            label: "Category",
            type: "category",
            value: category,
        }]);
        setFormValues(values);
        setToggle(false);
    };

    const removeItem = (index) => {
        var values = [...formValues];
        values.splice(index, 1)
        setFormValues(values);
    }

    const addBtnClick = (e) => {
        e.preventDefault();
        setToggle(true);
    };

    const fetchData = async () => {
        setLoading(true);
        let result = await PerformRequest('budget/budgets');
        let result2 = await PerformRequest('income/incomes');

        if(result) {
            setBudget(result);
            setBudgetItems(result.items);
            setIncomeTotal(result2.total);
            setLoading(false);
        }
    }

    const deleteBudgetItem = async (id) => {
        let result = await PerformRequest('budget/budgets/' + id + '/item', 'DELETE');

        if(result) {
            fetchData();
        }
    }

    useEffect(() => {
        fetchData()
            .catch(console.error);
    }, []);

    if(loading) {
        return <div>
            <ClipLoader
                className="ml-5"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>;
    }

    var totalBudget = 0;
    var totalBudgetItems = formValues.map((obj) => {
        totalBudget += parseFloat(obj[1]["value"]);
    });

    if(isNaN(totalBudget)) {
        totalBudget = 0;
    }

    var budgetItemDisplay = "";

    if(!isEmpty(budget) && budgetItems && budgetItems.length > 0) {
        budgetItems.map((item) => {
            totalBudget += parseFloat(item["total"]);
        });

        budgetItemDisplay = budgetItems.map((item) => (
            <div className="input-group mb-3">
                <span className="input-group-text">Name:</span>
                <input
                    className="form-control"
                    type="text"
                    value={item.name}
                />
                <span className="input-group-text">Total:</span>
                <input
                    className="form-control"
                    type="text"
                    value={item.total}
                />
                <span className="input-group-text">Category:</span>
                <input
                    className="form-control"
                    type="text"
                    value={item.categoryName}
                />
                <button className="btn btn-danger" onClick={() => deleteBudgetItem(item.id)}>Delete</button>
            </div>
        ));
    }


    var remainder = incomeTotal - totalBudget;
    var remainderClass = remainder > 0 ? "text-success" : "text-danger";

    return (
        <div>
            {isEmpty(budget) &&
                <div className="row row-cols-sm-12 row-cols-md-12 g-4">
                    <div className="col col-sm-12">
                        <div className="card card-tile card-section shadow p-3 mb-3 rounded">
                            <div className="card-header card-header-tile"></div>
                            <div className="card-body">
                                <p>
                                    No Budget Found! Please click here to add one.
                                </p>

                                <BudgetCreate/>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {!isEmpty(budget) &&
                <>
                    <div className="row row-cols-sm-4 row-cols-md-4 g-4">
                        <div className="col col-sm-12">
                            <div className="card card-tile card-section shadow p-3 mb-3 rounded">
                                <div className="card-header card-header-tile">Income</div>
                                <div className="card-body">
                                    <CurrencyFormat value={incomeTotal} displayType={'text'}
                                                    thousandSeparator={true} prefix={'$'}/>
                                </div>
                            </div>
                        </div>

                        <div className="col col-sm-12">
                            <div className="card card-tile card-section shadow p-3 mb-3 rounded">
                                <div className="card-header card-header-tile">Budget</div>
                                <div className="card-body">
                                    <CurrencyFormat value={totalBudget.toFixed(2)} displayType={'text'}
                                                    thousandSeparator={true} prefix={'$'}/>
                                </div>
                            </div>
                        </div>

                        <div className="col col-sm-12">
                            <div className="card card-tile card-section shadow p-3 mb-3 rounded">
                                <div className="card-header card-header-tile">Remainder</div>
                                <div className={"card-body " + remainderClass }>
                                    <CurrencyFormat value={remainder.toFixed(2)} displayType={'text'}
                                                    thousandSeparator={true} prefix={'$'}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row row-cols-sm-12 row-cols-md-12 g-4">
                        <div className="col-sm-12">
                            <div className="card card-tile card-section shadow p-3 mb-3 rounded">
                                <div className="card-header card-header-tile">Add Budget Items</div>
                                <div className="card-body">
                                    <div className="row">
                                        {budgetItemDisplay}
                                        {formValues.map((obj, index) => (
                                            <div className="input-group mb-3">
                                                {obj.map((obj, index2) => (
                                                    <>
                                                        <Input
                                                            key={index2}
                                                            objValue={obj}
                                                            onChange={handleChange}
                                                            index={index}
                                                        />
                                                    </>

                                                ))

                                                }

                                                <button className="btn btn-danger" onClick={() => {
                                                    removeItem(index)
                                                }}>Remove
                                                </button>
                                            </div>
                                        ))}
                                    </div>

                                    <form action="#" onSubmit={handleSubmit}>
                                        {!toggle ? (
                                            <div className="input-group">
                                                <button className="btn btn-outline-primary mb-3"
                                                        onClick={addBtnClick}>
                                                    Add new
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    ref={inputRef}/>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Amount"
                                                    ref={selectRef}/>

                                                <CategoryHelper refName={select2Ref}
                                                                otherHandler={(e) => setCategory(e)}/>

                                                <button className="btn btn-primary" onClick={handleAddField}>
                                                    Add
                                                </button>
                                            </div>
                                        )}


                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}


export default Budget;