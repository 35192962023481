import React, {useRef, useState} from "react";
import CategoryHelper from "./CategoryHelper";
import {PerformRequest} from "../../utils/JsonFetcher";

function RenameForm({categoryOptions, defaultTerm}) {
    const [selectedCategory, setSelectedCategory] = useState("");
    var term = useRef(defaultTerm || null);

    const handleRuleSubmit = async (e) => {
        e.preventDefault();
        let result = await PerformRequest('rules/rule', 'PUT', {name: term.current.value, categoryName: selectedCategory, term: term.current.value});
        let result2 = await PerformRequest('rules/apply', 'POST', {id: result.id});
    }

    return (
        <form onSubmit={handleRuleSubmit}>
            <div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Search Term:</span>
                    <input
                        id="term"
                        className="form-control"
                        type="text"
                        ref={term}
                        defaultValue={defaultTerm}
                        placeholder="My Electric Bill"
                    />
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text">Category:</span>
                    <CategoryHelper otherHandler={setSelectedCategory}
                                    extraOptions={categoryOptions}/>
                </div>

                <div className="input-group">
                    <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Submit</button>
                </div>
            </div>
        </form>
    );
}

export default RenameForm;