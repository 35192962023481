import React from "react";
export default function Input({ objValue, onChange, index }) {
    const { label, type, value } = objValue;
    return (
        <>
            <span className="input-group-text">{label}:</span>
            <input
                type={"text"}
                id={label}
                value={value || ""}
                className="form-control"
                onChange={(e) => onChange(e, index)}
            />

        </>
    );
}