import React, {useEffect, useState} from 'react';
import {PerformRequest} from "../../utils/JsonFetcher";
import {Link, redirect, useNavigate} from "react-router-dom";
import AccountHelper from "./AccountHelper";
import Modal from "../../components/Modal";

function BudgetCreate() {
    const [name, setName] = useState("");
    const [loginError, setLoginError] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();

        const setData = async (name) => {
            let payload = {
                name: name
            };
            let result = await PerformRequest('budget/budgets', "PUT", payload);

            if (result && result['error'] != undefined) {
                setLoginError(true);
            } else {
                window.location.reload();
            }
        }

        setData(name)
            .catch(console.error);
    };

    var content = <div className="card card-section shadow p-3 mb-3 rounded">
        <div className="card-header card-header-tile mb-4">Create Budget</div>

        <div className="card-body">
            {loginError &&
                <h5 className="text-danger"><i className="bi bi-exclamation-triangle-fill"></i> Error Creating Budget.</h5>
            }

            {!loginError &&
                <>
                    <form onSubmit="#">
                        <div>
                            <div className="input-group mb-3">
                                <span className="input-group-text">Name:</span>
                                <input
                                    id="name"
                                    className="form-control"
                                    type="text"
                                    value={name}
                                    placeholder="Chase"
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                        </div>
                    </form>
                </>
            }
        </div>
    </div>;

    return (
        <>
            <Modal title="Create Budget" buttonText="Create Budget" body={content}
                   buttonClasses="btn btn-outline-primary mb-3" saveButtonText="Submit"
                   iconClass="bi-stack" SaveHandler={handleSubmit}/>
        </>
    );
}

export default BudgetCreate;