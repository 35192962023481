import React, {useEffect, useState} from "react";
import {GetUser, IsLoggedIn} from "../../utils/TokenStorage";
import {PerformRequest} from "../../utils/JsonFetcher";
import {Link} from "react-router-dom";
import LineChart from "../fridge/LineChart";
import CurrencyFormat from "react-currency-format";
import PremiumCards from "./PremiumCards";

function PremiumSignup() {
    const [user, setUser] = useState("");
    const [premium, setPremium] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            let result = GetUser();

            if(result) {
                setUser(result);
                setPremium(result.premiumStatus);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }

        fetchData()
            .catch(console.error);
    }, []);

    if(loading) {
        return <div></div>;
    }

    var freeStatus = <button type="button" className="w-100 btn btn-lg btn-outline-primary">Sign up for
        free
    </button>;

    var proStatus = <button type="button" className="w-100 btn btn-lg btn-success">Get started</button>;
    var enterpriseStatus = <button type="button" className="w-100 btn btn-lg btn-warning">Contact us</button>;

    switch(premium) {
        case "":
            freeStatus = <button type="button" className="w-100 btn btn-lg btn-outline-primary">Thanks for being a free user!
            </button>;
            break;
        case "pro":
            proStatus = <button type="button" className="w-100 btn btn-lg btn-success">Thanks for being a pro user!
            </button>;
            break;
        case "enterprise":
            enterpriseStatus = <button type="button" className="w-100 btn btn-lg btn-outline-warning">Thanks for being an enterprise customer!
            </button>;
            break;
    }

    return (
        <div className={IsLoggedIn() ? "" : "landing landing-full"}>
            { !IsLoggedIn() &&
                <div className="navbar bg-dark">
                    <div className="container homepage-container">
                        <div>
                            <h4 className="pt-2"><Link to="/">MoneyMan Tracker</Link></h4>
                        </div>
                        <div>
                            <Link to="/login">
                                Login
                            </Link>

                            <Link to="/premium/signup">
                                Register
                            </Link>
                        </div>
                    </div>
                </div>
            }

            <PremiumCards />
        </div>
            );
            }

            export default PremiumSignup;