import React, {useState, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {PerformRequest, GET, POST, PUT} from '../../utils/JsonFetcher';
import CurrencyFormat from 'react-currency-format';
import Chart from "./Chart";
import {SetAlert} from "../../utils/AlertUtils";
import Select from "react-select";
import LineChart from "./LineChart";
import ClipLoader from "react-spinners/ClipLoader";
import Input from "./Input";
import CategoryHelper from "./CategoryHelper";

function Income() {
    function isEmpty(obj) {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }

        return true;
    }

    const [budgetItems, setBudgetItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState("");

    const [formValues, setFormValues] = useState([]);
    const [toggle, setToggle] = useState(false);

    const inputRef = useRef();
    const selectRef = useRef();

    const handleChange = (e, index) => {
        const values = [...formValues];
        values[index].value = e.target.value;
        setFormValues(values);
    };

    const handleSubmit = async () => {
        const values = [...formValues];

        var payload = [];
        values.map((value, index) => {
            var payloadObject = {};

            value.map((item, index) => {
                payloadObject[item.type] = item.value;
            })

            payload.push(payloadObject);
        })

        if(budgetItems && budgetItems.length > 0) {
            budgetItems.map((item, index) => {
                var payloadObject = {};
                payloadObject["name"] = item.name;
                payloadObject["amount"] = item.total;
                payloadObject["category"] = item.type;
                payload.push(payloadObject);
            })
        }

        let result = await PerformRequest('income/income', PUT, payload);

        if(result) {
          //  window.location.reload();
        }
    };

    const handleAddField = (e) => {
        e.preventDefault();
        const values = [...formValues];
        values.push([{
            label: "Name",
            type: "name",
            value: inputRef.current.value,
        },
            {
                label: "Amount",
                type: "amount",
                value: selectRef.current.value,
            },
            {
                label: "Category",
                type: "category",
                value: category,
            }]);
        setFormValues(values);
        setToggle(false);
    };

    const removeItem = (index) => {
        var values = [...formValues];
        values.splice(index, 1)
        setFormValues(values);
    }

    const addBtnClick = (e) => {
        e.preventDefault();
        setToggle(true);
    };

    const fetchData = async () => {
        setLoading(true);
        let result = await PerformRequest('income/incomes');

        if(result) {
            setBudgetItems(result.items);
            setLoading(false);
        }
    }

    const deleteBudgetItem = async (id) => {
        let result = await PerformRequest('income/income/' + id, 'DELETE');

        if(result) {
            fetchData();
        }
    }

    useEffect(() => {
        fetchData()
            .catch(console.error);
    }, []);

    if(loading) {
        return <div>
            <ClipLoader
                className="ml-5"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>;
    }

    var totalBudget = 0;
    var totalBudgetItems = formValues.map((obj) => {
        totalBudget += parseFloat(obj[1]["value"]);
    });

    if(isNaN(totalBudget)) {
        totalBudget = 0;
    }

    if(budgetItems && budgetItems.length > 0) {
        budgetItems.map((item) => {
            totalBudget += parseFloat(item["total"]);
        });

        var budgetItemDisplay = "";

        budgetItemDisplay = budgetItems.map((item) => (
            <div className="input-group mb-3">
                <span className="input-group-text">Name:</span>
                <input
                    className="form-control"
                    type="text"
                    value={item.name}
                />
                <span className="input-group-text">Total:</span>
                <input
                    className="form-control"
                    type="text"
                    value={item.total}
                />
                <span className="input-group-text">Category:</span>
                <input
                    className="form-control"
                    type="text"
                    value={item.categoryName || item.type}
                />
                <button className="btn btn-danger" onClick={() => deleteBudgetItem(item.id)}>Delete</button>
            </div>
        ));
    }


    var types = [
        {value: "Cash", label: "Cash"},
        {value: "Commission", label: "Commission"},
        {value: "Dividends", label: "Dividends"},
        {value: "Income", label: "Income"},
        {value: "Interest", label: "Interest"},
        {value: "Misc", label: "Misc"},
        {value: "Pension", label: "Pension"},
        {value: "Paypal", label: "Paypal"},
        {value: "Rental Income", label: "Rental Income"},
        {value: "Royalties", label: "Royalties"},
        {value: "Salary", label: "Salary"},
        {value: "Social Security", label: "Social Security"},
        {value: "Tips", label: "Tips"},
        {value: "Unemployment", label: "Unemployment"},
        {value: "Venmo", label: "Venmo"},
        {value: "Zelle", label: "Zelle"}
    ];

    return (
        <div>
                <div className="row row-cols-sm-12 row-cols-md-12 g-4">
                    <div className="col col-sm-12">
                        <div className="card card-tile card-section shadow p-3 mb-3 rounded">
                            <div className="card-header card-header-tile">Add Income</div>
                            <div className="card-body">
                                <div className="row">

                                    <CurrencyFormat value={totalBudget} displayType={'text'} thousandSeparator={true} prefix={'$'}/>

                                    {budgetItemDisplay}
                                    {formValues.map((obj, index) => (
                                        <div className="input-group mb-3">
                                            {obj.map((obj, index2) => (
                                                <>
                                                    <Input
                                                        key={index2}
                                                        objValue={obj}
                                                        onChange={handleChange}
                                                        index={index}
                                                    />
                                                </>

                                            ))

                                            }

                                            <button className="btn btn-danger" onClick={() => {removeItem(index)}}>Remove</button>
                                        </div>
                                    ))}
                                </div>

                                <form action="#" onSubmit={handleSubmit}>
                                    {!toggle ? (
                                        <div className="input-group">
                                            <button className="btn btn-outline-primary mb-3" onClick={addBtnClick}>
                                                Add new
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Name"
                                                ref={inputRef}/>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Amount"
                                                ref={selectRef}/>

                                            <CategoryHelper replacementOptions={types} otherHandler={(e) => setCategory(e)} />

                                            <button className="btn btn-primary" onClick={handleAddField}>
                                                Add
                                            </button>
                                        </div>
                                    )}


                                    <button type="submit" className="btn btn-primary">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>



        </div>
    );
}

export default Income;